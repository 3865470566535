<template>
  <v-row
    v-if="!isLoading"
    class="match-height"
  >
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.products')} / ${$t('menu.cars')} / ${$t('lbl.cargaLote')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'cars-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              v-if="lote !== null"
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :loading="processUpload"
                :disabled="!lote.listo"
                @click="acceptUpload()"
              >
                <span>{{ $t('btn.update') }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
            <v-col
              v-if="lote !== null"
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="canceleUpload()"
              >
                <span>{{ $t('btn.canceleUpload') }}</span>
                <v-icon>{{ icons.mdiCancel }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-form class="multi-col-validation">
            <v-row v-if="lote === null && !uploadingFile">
              <v-col
                cols="12"
                md="3"
              >
                <v-file-input
                  v-model="file"
                  accept=".csv"
                  exc
                  show-size
                  outlined
                  dense
                  :label="$t('lbl.file')"
                  hide-details
                  @change="setFile"
                ></v-file-input>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <h3 class="mb-3 text-center">
                      {{ $t('lbl.orderFileColumn') }}
                    </h3>
                    <v-img
                      v-if="nameB2B === 'ttttt'"
                      contain
                      lazy-src="@/assets/img/placeholder_lote_car_ttttt.png"
                      src="@/assets/img/placeholder_lote_car_ttttt.png"
                    >
                    </v-img>
                    <v-img
                      v-else
                      contain
                      lazy-src="@/assets/img/placeholder_lote_car.png"
                      src="@/assets/img/placeholder_lote_car.png"
                    >
                    </v-img>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        md="5"
                      >
                        <h3 class="mb-3 mt-2 text-center">
                          {{ $t('lbl.descriptionCampos') }}
                        </h3>
                      </v-col>
                      <v-col
                        cols="12"
                        md="1"
                      >
                        <v-btn
                          color="primary"
                          class="mb-4 me-3"
                          @click="dowloadExportCsv"
                        >
                          <v-icon>{{ icons.mdiFileDownload }}</v-icon>
                          <span>{{ $t('lbl.dowloadExample') }}</span>
                        </v-btn>
                      </v-col>

                      <v-col
                        cols="12"
                        md="12"
                      >
                        <ol>
                          <li><b>Marca</b>: marca del vehículo, <u>ejemplo</u>: <i>Hyundai</i></li>
                          <li><b>Modelo</b>: modelo del vehículo, <u>ejemplo</u>: <i>Elantra</i></li>
                          <li>
                            <b>Transmisión</b>: tipos o sistemas de transmisión, <u>ejemplo</u>: <i>Automático</i>
                          </li>
                          <li><b>Carrocería</b>: anatomía del vehículo, <u>ejemplo</u>: <i>Sedan</i></li>
                          <li><b>Combustible</b>: tipo de combustible, <u>ejemplo</u>: <i>Diesel</i></li>
                          <li><b>Puertas</b>: cantidad de puertas, <u>ejemplo</u>: <i>4</i></li>
                          <li><b>Pax</b>: cantidad de personas, <u>ejemplo</u>: <i>5</i></li>
                          <li><b>Maletas Grandes</b>: cantidad de maletas, <u>ejemplo</u>: <i>2</i></li>
                          <li><b>Maletas Pequeñas</b>: cantidad de maletas, <u>ejemplo</u>: <i>3</i></li>
                          <li>
                            <b>Tanque Combustible</b>: capacidad del tanque de combustible, <u>ejemplo</u>: <i>50</i>
                          </li>
                          <li><b>UM</b>: unidad de medidas para el tanque de combustible, <u>ejemplo</u>: <i>L</i></li>
                          <li><b>Consumo</b>: gasto promedio por kilómetro, <u>ejemplo</u>: <i>12</i></li>
                          <li><b>Motor</b>: cilindrada del motor, <u>ejemplo</u>: <i>2.0</i></li>
                          <li>
                            <b>Características</b>: elementos y detalles del vehículo, <u>ejemplo</u>:
                            <i>A/C, Airbag, AM/FM, Bluetooth, GPS, Herramientas, Asientos para niños, Cierre
                              centralizado, Sistema ABS</i>
                          </li>
                          <fragment v-if="nameB2B === 'ttttt'">
                            <li>
                              <b>Imágenes</b>: links de las imágenes del producto en el CSV, separadas por comas,
                              <u>ejemplo</u>:
                              <i>23706/Hyundai-elantra-automatico-001.jpeg,23706/Hyundai-elantra-automatico-002.jpeg,23706/Hyundai-elantra-automatico-003.jpeg</i>
                            </li>
                            <li>
                              <b>Videos</b>: links de las imágenes del producto en el CSV, separadas por comas,
                              <u>ejemplo</u>:
                              <i>23706/Hyundai-elantra-automatico-001.avi,23706/Hyundai-elantra-automatico-002.mpg</i>
                            </li>
                            <li>
                              <b>Id Externo</b>: id del producto en la BD externa de Travelzun, sólo informativo,
                              <u>ejemplo</u>:
                              <i>33</i>
                            </li>
                          </fragment>
                        </ol>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row v-if="uploadingFile">
              <v-col
                cols="12"
                md="4"
              ></v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-skeleton-loader type="card"></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row v-else>
              <fragment v-if="lote !== null">
                <v-col
                  cols="10"
                  offset-md="1"
                >
                  <v-expansion-panels>
                    <v-expansion-panel v-if="marcasChange.length > 0">
                      <v-expansion-panel-header>
                        <template v-slot:default>
                          <v-row>
                            <v-col cols="2">
                              <h4 class="mt-2">
                                {{ $t('menu.marca') }}
                              </h4>
                            </v-col>
                            <v-col cols="1">
                              <v-tooltip
                                top
                                color="error"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="error">
                                      {{ icons.mdiInformationOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('lbl.problemsMarcas') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, index) in marcasChange"
                                :key="index"
                              >
                                <td width="80%">
                                  {{ item }}
                                </td>
                                <td>
                                  <v-tooltip
                                    top
                                    color="primary"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="modalMarca(item)"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiSquareEditOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.edit') }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="modelosChange.length > 0">
                      <v-expansion-panel-header>
                        <template v-slot:default>
                          <v-row>
                            <v-col cols="2">
                              <h4 class="mt-2">
                                {{ $t('menu.modelo') }}
                              </h4>
                            </v-col>
                            <v-col cols="1">
                              <v-tooltip
                                top
                                color="error"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="error">
                                      {{ icons.mdiInformationOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('lbl.problemsModelos') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, index) in modelosChange"
                                :key="index"
                              >
                                <td width="80%">
                                  {{ item }}
                                </td>
                                <td>
                                  <v-tooltip
                                    top
                                    color="primary"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="modalModelo(item)"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiSquareEditOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.edit') }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="transmisionesChange.length > 0">
                      <v-expansion-panel-header>
                        <template v-slot:default>
                          <v-row>
                            <v-col cols="2">
                              <h4 class="mt-2">
                                {{ $t('menu.trasnmision') }}
                              </h4>
                            </v-col>
                            <v-col cols="1">
                              <v-tooltip
                                top
                                color="error"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="error">
                                      {{ icons.mdiInformationOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('lbl.problemsTransmisiones') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, index) in transmisionesChange"
                                :key="index"
                              >
                                <td width="80%">
                                  {{ item }}
                                </td>
                                <td>
                                  <v-tooltip
                                    top
                                    color="primary"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="modalTransmision(item)"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiSquareEditOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.edit') }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="tipoAutosChange.length > 0">
                      <v-expansion-panel-header>
                        <template v-slot:default>
                          <v-row>
                            <v-col cols="2">
                              <h4 class="mt-2">
                                {{ $t('menu.typeCar') }}
                              </h4>
                            </v-col>
                            <v-col cols="1">
                              <v-tooltip
                                top
                                color="error"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="error">
                                      {{ icons.mdiInformationOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('lbl.problemsTipoAutos') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, index) in tipoAutosChange"
                                :key="index"
                              >
                                <td width="80%">
                                  {{ item }}
                                </td>
                                <td>
                                  <v-tooltip
                                    top
                                    color="primary"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="modalTipoAuto(item)"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiSquareEditOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.edit') }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="combustiblesChange.length > 0">
                      <v-expansion-panel-header>
                        <template v-slot:default>
                          <v-row>
                            <v-col cols="2">
                              <h4 class="mt-2">
                                {{ $t('menu.combustible') }}
                              </h4>
                            </v-col>
                            <v-col cols="1">
                              <v-tooltip
                                top
                                color="error"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="error">
                                      {{ icons.mdiInformationOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('lbl.problemsCombustible') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, index) in combustiblesChange"
                                :key="index"
                              >
                                <td width="80%">
                                  {{ item }}
                                </td>
                                <td>
                                  <v-tooltip
                                    top
                                    color="primary"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="modalCombustibles(item)"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiSquareEditOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.edit') }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="caracteristicasChange.length > 0">
                      <v-expansion-panel-header>
                        <template v-slot:default>
                          <v-row>
                            <v-col cols="2">
                              <h4 class="mt-2">
                                {{ $t('menu.caracteristicas') }}
                              </h4>
                            </v-col>
                            <v-col cols="1">
                              <v-tooltip
                                top
                                color="error"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="error">
                                      {{ icons.mdiInformationOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('lbl.problemsCaracteristicas') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, index) in caracteristicasChange"
                                :key="index"
                              >
                                <td width="80%">
                                  {{ item }}
                                </td>
                                <td>
                                  <v-tooltip
                                    top
                                    color="primary"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="modalCaracteristicas(item)"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiSquareEditOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.edit') }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="tagsChange.length > 0">
                      <v-expansion-panel-header>
                        <template v-slot:default>
                          <v-row>
                            <v-col cols="2">
                              <h4 class="mt-2">
                                Tag
                              </h4>
                            </v-col>
                            <v-col cols="1">
                              <v-tooltip
                                top
                                color="error"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="error">
                                      {{ icons.mdiInformationOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('lbl.problemsTags') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, index) in tagsChange"
                                :key="index"
                              >
                                <td width="80%">
                                  {{ item }}
                                </td>
                                <td>
                                  <v-tooltip
                                    top
                                    color="primary"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="modalTags(item)"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiSquareEditOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.edit') }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>

                <fragment v-if="lote.listo">
                  <v-col cols="12">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-uppercase">
                              {{ $t('menu.marca') }}
                            </th>
                            <th class="text-uppercase">
                              {{ $t('menu.modelo') }}
                            </th>
                            <th class="text-uppercase">
                              {{ $t('menu.trasnmision') }}
                            </th>
                            <th class="text-uppercase">
                              {{ $t('menu.typeCar') }}
                            </th>
                            <th class="text-uppercase">
                              {{ $t('menu.combustible') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <fragment
                            v-for="(item, index) in lote.lote"
                            :key="index"
                          >
                            <tr>
                              <td>
                                {{ item.marca.name }}
                                <v-btn
                                  icon
                                  @click="showItem(index)"
                                >
                                  <v-icon>
                                    {{ showRow && index === indexRow ? icons.mdiMenuDown : icons.mdiMenuRight }}
                                  </v-icon>
                                </v-btn>
                                <v-tooltip
                                  top
                                  color="error"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="deleteItem(item)"
                                    >
                                      <v-icon color="error">
                                        {{ icons.mdiDeleteOutline }}
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $t('btn.delete') }}</span>
                                </v-tooltip>
                              </td>
                              <td>
                                <span v-if="item.modelo">
                                  <span v-if="item.modelo.id">
                                    {{ allModelos.filter(e => e.id === item.modelo.id)[0].name }}
                                  </span>
                                </span>
                              </td>
                              <td>
                                <span v-if="item.transmision">
                                  <span v-if="item.transmision.id">
                                    {{ allTransmisiones.filter(e => e.id === item.transmision.id)[0].name }}
                                  </span>
                                </span>
                              </td>
                              <td>
                                <span v-if="item.tipo_auto">
                                  <span v-if="item.tipo_auto.id">
                                    {{ allTipoAuto.filter(e => e.id === item.tipo_auto.id)[0].name }}
                                  </span>
                                </span>
                              </td>
                              <td>
                                <span v-if="item.combustible">
                                  <span v-if="item.combustible.id">
                                    {{ allCombustibles.filter(e => e.id === item.combustible.id)[0].name }}
                                  </span>
                                </span>
                              </td>
                            </tr>
                            <tr v-if="showRow && index === indexRow">
                              <td colspan="5">
                                <v-row v-if="item.caracteristicas">
                                  <v-col
                                    v-if="item.caracteristicas.length > 0"
                                    cols="12"
                                    md="12"
                                  >
                                    <strong>{{ $t('menu.caracteristicas') }}</strong><br />
                                    <span
                                      v-for="(inter, indInter) in item.caracteristicas"
                                      :key="indInter"
                                    >
                                      <span v-if="inter.id">
                                        {{ allCaracteristicas.filter(e => e.id === inter.id)[0].name }}
                                      </span>
                                      <span v-if="indInter <= item.caracteristicas.length - 2">,</span>
                                    </span>
                                  </v-col>
                                </v-row>

                                <v-row>
                                  <v-col
                                    v-if="item.cant_puertas"
                                    cols="12"
                                    md="2"
                                  >
                                    <b>{{ $t('lbl.cantPuertas') }}</b>:{{ item.cant_puertas }}
                                  </v-col>
                                  <v-col
                                    v-if="item.cant_paxs"
                                    cols="12"
                                    md="2"
                                  >
                                    <b>{{ $t('lbl.cantPaxs') }}</b>:{{ item.cant_paxs }}
                                  </v-col>
                                  <v-col
                                    v-if="item.cant_maletas_small"
                                    cols="12"
                                    md="2"
                                  >
                                    <b>{{ $t('lbl.cantMaletas') }} {{ $t('lbl.small') }}</b>:{{ item.cant_maletas_small }}
                                  </v-col>
                                  <v-col
                                    v-if="item.cant_maletas_big"
                                    cols="12"
                                    md="2"
                                  >
                                    <b>{{ $t('lbl.cantMaletas') }} {{ $t('lbl.small') }}</b>:{{ item.cant_maletas_big }}
                                  </v-col>

                                  <v-col
                                    v-if="item.capacidad_combustible"
                                    cols="12"
                                    md="3"
                                  >
                                    <b>{{ $t('lbl.capacityComb') }}</b>:{{ `${item.capacidad_combustible} ${item.um_combustible}` }}
                                  </v-col>
                                  <v-col
                                    v-if="item.consumo_combustible"
                                    cols="12"
                                    md="3"
                                  >
                                    <b>{{ $t('lbl.consumoComb') }}</b>:{{ `${item.consumo_combustible} Km/${item.um_combustible}` }}
                                  </v-col>
                                  <v-col
                                    v-if="item.motor"
                                    cols="12"
                                    md="2"
                                  >
                                    <b>{{ $t('lbl.motor') }}</b>:{{ `${item.motor} ${item.um_combustible}` }}
                                  </v-col>
                                  <!--<v-col
                                    v-if="item.sugerido"
                                    cols="12"
                                    md="2"
                                  >
                                    <b>{{ $t('lbl.sugerido') }}</b>
                                    <span v-if="item.number_sugerido">
                                      (# {{ $t('lbl.orderSugerido') }}: {{ item.number_sugerido }})
                                    </span>
                                  </v-col>-->
                                  <v-col
                                    v-if="item.id_externo"
                                    cols="12"
                                    md="2"
                                  >
                                    <b>Id Externo</b>
                                    <i>
                                      {{ item.id_externo }}
                                    </i>
                                  </v-col>
                                </v-row>

                                <v-row v-if="item.imagenes.length > 0">
                                  <v-col
                                    v-for="(img, indImg) in item.imagenes"
                                    :key="indImg"
                                    cols="12"
                                    md="3"
                                  >
                                    <!--<span v-if="item.tags_img">
                                      <span v-if="item.tags_img.length > 0">
                                        <span v-if="item.tags_img[indImg]">
                                          <i v-if="item.tags_img[indImg].name">
                                            {{ item.tags_img[indImg].name }}
                                          </i>
                                        </span>
                                      </span> </span><br />-->
                                    <v-img
                                      :src="nameB2B === 'ttttt' ? `${rutaS3}${img}` : img"
                                      max-height="150"
                                      max-width="250"
                                    >
                                    </v-img>
                                  </v-col>
                                </v-row>
                              </td>
                            </tr>
                          </fragment>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </fragment>
              </fragment>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>

    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDeleteItem"
      scrollable
      max-width="380px"
    >
      <v-card
        v-if="model.marca"
        max-height="400px"
      >
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.addDelete', { item: model.marca.name }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDeleteItem = !isDialogVisibleDeleteItem"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loadingDelete"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR UPDATE ALL -->
    <v-dialog
      v-model="isDialogUpdate"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.acceptChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p>{{ $t('msg.processUpload1') }}</p>
          <p>{{ $t('msg.processUpload2') }}</p>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogUpdate = !isDialogUpdate"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="cancelarUpload"
            @click="confirmUpdateAll()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR CANCEL UPLOAD -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.cancele')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.canceleUpload', { item: 'autos' }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="cancelarUpload"
            @click="confirmCancele()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ADD CHANGE NOM -->
    <v-dialog
      v-model="isAddChange"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.insert')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addChange', {
              item:
                type === 'marca'
                  ? model.marca.name
                  : type === 'modelo'
                    ? model.modelo.name
                    : type === 'transmision'
                      ? model.transmision.name
                      : type === 'tipo_auto'
                        ? model.tipo_auto.name
                        : type === 'caracteristicas'
                          ? model.caracteristicas.name
                          : type === 'combustible'
                            ? model.combustible.name
                            : type === 'tags_img'
                              ? model.tags_img.name
                              : '',
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddChange = !isAddChange"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddChange()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL REMPLACE-->
    <v-dialog
      v-model="isAddRemplace"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.remplace')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addRemplace', {
              remplace:
                type === 'marca'
                  ? model.marca.name
                  : type === 'modelo'
                    ? model.modelo.name
                    : type === 'transmision'
                      ? model.transmision.name
                      : type === 'tipo_auto'
                        ? model.tipo_auto.name
                        : type === 'caracteristicas'
                          ? model.caracteristicas.name
                          : type === 'combustible'
                            ? model.combustible.name
                            : type === 'tags_img'
                              ? model.tags_img.name
                              : '',
              item:
                type === 'marca'
                  ? model.marca.id
                    ? itemsMarcas.filter(e => e.id === model.marca.id).length > 0
                      ? itemsMarcas.filter(e => e.id === model.marca.id)[0].name
                      : ''
                    : ''
                  : type === 'modelo'
                    ? model.modelo.id
                      ? itemsModelos.filter(e => e.id === model.modelo.id).length > 0
                        ? itemsModelos.filter(e => e.id === model.modelo.id)[0].name
                        : ''
                      : ''
                    : type === 'transmision'
                      ? model.transmision.id
                        ? itemsTransmisiones.filter(e => e.id === model.transmision.id).length > 0
                          ? itemsTransmisiones.filter(e => e.id === model.transmision.id)[0].name
                          : ''
                        : ''
                      : type === 'tipo_auto'
                        ? model.tipo_auto.id
                          ? itemsTipoAuto.filter(e => e.id === model.tipo_auto.id).length > 0
                            ? itemsTipoAuto.filter(e => e.id === model.tipo_auto.id)[0].name
                            : ''
                          : ''
                        : type === 'caracteristicas'
                          ? model.caracteristicas.id
                            ? allCaracteristicas.filter(e => e.id === model.caracteristicas.id).length > 0
                              ? allCaracteristicas.filter(e => e.id === model.caracteristicas.id)[0].name
                              : ''
                            : ''
                          : type === 'combustible'
                            ? model.combustible.id
                              ? itemsCombustibles.filter(e => e.id === model.combustible.id).length > 0
                                ? itemsCombustibles.filter(e => e.id === model.combustible.id)[0].name
                                : ''
                              : ''
                            : type === 'tags_img'
                              ? model.tags_img.id
                                ? itemsTags.filter(e => e.id === model.tags_img.id).length > 0
                                  ? itemsTags.filter(e => e.id === model.tags_img.id)[0].name
                                  : ''
                                : ''
                              : '',
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddRemplace = !isAddRemplace"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddRemplace()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL ASOCIATE-->
    <v-dialog
      v-model="isAddAsociate"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.asociate')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addAsociate', {
              asociate:
                type === 'marca'
                  ? model.marca.name
                  : type === 'modelo'
                    ? model.modelo.name
                    : type === 'transmision'
                      ? model.transmision.name
                      : type === 'tipo_auto'
                        ? model.tipo_auto.name
                        : type === 'caracteristicas'
                          ? model.caracteristicas.name
                          : type === 'combustible'
                            ? model.combustible.name
                            : type === 'tags_img'
                              ? model.tags_img.name
                              : '',
              item:
                type === 'marca'
                  ? model.marca.id
                    ? itemsMarcas.filter(e => e.id === model.marca.id).length > 0
                      ? itemsMarcas.filter(e => e.id === model.marca.id)[0].name
                      : ''
                    : ''
                  : type === 'modelo'
                    ? model.modelo.id
                      ? itemsModelos.filter(e => e.id === model.modelo.id).length > 0
                        ? itemsModelos.filter(e => e.id === model.modelo.id)[0].name
                        : ''
                      : ''
                    : type === 'transmision'
                      ? model.transmision.id
                        ? itemsTransmisiones.filter(e => e.id === model.transmision.id).length > 0
                          ? itemsTransmisiones.filter(e => e.id === model.transmision.id)[0].name
                          : ''
                        : ''
                      : type === 'tipo_auto'
                        ? model.tipo_auto.id
                          ? itemsTipoAuto.filter(e => e.id === model.tipo_auto.id).length > 0
                            ? itemsTipoAuto.filter(e => e.id === model.tipo_auto.id)[0].name
                            : ''
                          : ''
                        : type === 'caracteristicas'
                          ? model.caracteristicas.id
                            ? allCaracteristicas.filter(e => e.id === model.caracteristicas.id).length > 0
                              ? allCaracteristicas.filter(e => e.id === model.caracteristicas.id)[0].name
                              : ''
                            : ''
                          : type === 'combustible'
                            ? model.combustible.id
                              ? itemsCombustibles.filter(e => e.id === model.combustible.id).length > 0
                                ? itemsCombustibles.filter(e => e.id === model.combustible.id)[0].name
                                : ''
                              : ''
                            : type === 'tags_img'
                              ? model.tags_img.id
                                ? itemsTags.filter(e => e.id === model.tags_img.id).length > 0
                                  ? itemsTags.filter(e => e.id === model.tags_img.id)[0].name
                                  : ''
                                : ''
                              : '',
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddAsociate = !isAddAsociate"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddAsociate()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL DELETE VALUE-->
    <v-dialog
      v-model="isAddDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addDelete', {
              item:
                type === 'marca'
                  ? model.marca.name
                  : type === 'modelo'
                    ? model.modelo.name
                    : type === 'transmision'
                      ? model.transmision.name
                      : type === 'tipo_auto'
                        ? model.tipo_auto.name
                        : type === 'caracteristicas'
                          ? model.caracteristicas.name
                          : type === 'combustible'
                            ? model.combustible.name
                            : type === 'tags_img'
                              ? model.tags_img.name
                              : '',
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddDelete = !isAddDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CHANGE MARCA -->
    <v-dialog
      v-model="isChangeMarca"
      max-width="750px"
    >
      <v-card height="300px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.marca"
            class="mt-5"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="model.marca.name"
                :label="$t('menu.marca')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="model.marca.id"
                :items="itemsMarcas"
                :search-input.sync="searchMarcas"
                hide-details
                hide-selected
                :label="$t('menu.marca')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
                single-line
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('menu.marca') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-file-input
                v-model="logo"
                accept="image/*"
                show-size
                outlined
                dense
                label="Logo"
                hide-details
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row class="ml-2">
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangeMarca = !isChangeMarca"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="model.marca"
              cols="12"
              md="6"
              offset-md="2"
            >
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    v-on="on"
                    @click="showAdd('marca')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.marca.id"
                    v-on="on"
                    @click="showRemplace('marca', model.marca)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.marca.id"
                    v-on="on"
                    @click="showAsociate('marca', model.marca)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('marca', model.marca.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CHANGE MODELOS -->
    <v-dialog
      v-model="isChangeModelo"
      max-width="750px"
    >
      <v-card height="300px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.modelo"
            class="mt-5"
          >
            <v-col
              cols="12"
              :md="model.modelo.marca_id ? '6' : '12'"
            >
              <v-text-field
                v-model="model.modelo.name"
                :label="$t('menu.modelo')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>

            <v-col
              v-if="model.modelo.marca_id"
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="model.modelo.id"
                :items="itemsModelos"
                :search-input.sync="searchModelos"
                hide-details
                hide-selected
                :label="$t('menu.modelo')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
                single-line
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('menu.modelo') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="model.modelo.marca_id"
                :items="itemsMarcas"
                :search-input.sync="searchMarcas"
                hide-details
                hide-selected
                :label="$t('menu.marca')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
                single-line
                @change="setMarca"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('menu.marca') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row class="ml-2">
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangeModelo = !isChangeModelo"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="model.modelo"
              cols="12"
              md="6"
              offset-md="2"
            >
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    :disabled="!model.modelo.marca_id"
                    v-on="on"
                    @click="showAdd('modelo')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.modelo.id || !model.modelo.marca_id"
                    v-on="on"
                    @click="showRemplace('modelo', model.modelo)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.modelo.id || !model.modelo.marca_id"
                    v-on="on"
                    @click="showAsociate('modelo', model.modelo)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('modelo', model.modelo.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CHANGE transmision -->
    <v-dialog
      v-model="isChangeTransmicion"
      max-width="750px"
    >
      <v-card height="220px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.transmision"
            class="mt-5"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="model.transmision.name"
                :label="$t('menu.trasnmision')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="model.transmision.id"
                :items="itemsTransmisiones"
                :search-input.sync="searchTransmisiones"
                hide-details
                hide-selected
                :label="$t('menu.trasnmision')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
                single-line
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('menu.trasnmision') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row class="ml-2">
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangeTransmicion = !isChangeTransmicion"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="model.transmision"
              cols="12"
              md="6"
              offset-md="2"
            >
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    v-on="on"
                    @click="showAdd('transmision')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.transmision.id"
                    v-on="on"
                    @click="showRemplace('transmision', model.transmision)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.transmision.id"
                    v-on="on"
                    @click="showAsociate('transmision', model.transmision)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('transmision', model.transmision.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CHANGE TIPO AUTO -->
    <v-dialog
      v-model="isChangeTipoAuto"
      max-width="750px"
    >
      <v-card height="360px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.tipo_auto"
            class="mt-5"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="model.tipo_auto.name"
                :label="$t('menu.typeCar')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="model.tipo_auto.id"
                :items="itemsTipoAuto"
                :search-input.sync="searchTipoAuto"
                hide-details
                hide-selected
                :label="$t('menu.typeCar')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
                single-line
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('menu.typeCar') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-textarea
                v-model="model.tipo_auto.description"
                :label="$t('lbl.description')"
                outlined
                no-resize
                rows="3"
                row-height="15"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row class="ml-2">
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangeTipoAuto = !isChangeTipoAuto"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="model.tipo_auto"
              cols="12"
              md="6"
              offset-md="2"
            >
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    v-on="on"
                    @click="showAdd('tipo_auto')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.tipo_auto.id"
                    v-on="on"
                    @click="showRemplace('tipo_auto', model.tipo_auto)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.tipo_auto.id"
                    v-on="on"
                    @click="showAsociate('tipo_auto', model.tipo_auto)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('tipo_auto', model.tipo_auto.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CHANGE COMBUSTIBLE -->
    <v-dialog
      v-model="isChangeCombustibles"
      max-width="750px"
    >
      <v-card height="220px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.combustible"
            class="mt-5"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="model.combustible.name"
                :label="$t('menu.combustible')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="model.combustible.id"
                :items="itemsCombustibles"
                :search-input.sync="searchCombustibles"
                hide-details
                hide-selected
                :label="$t('menu.combustible')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
                single-line
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('menu.combustible') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row class="ml-2">
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangeCombustibles = !isChangeCombustibles"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="model.combustible"
              cols="12"
              md="6"
              offset-md="2"
            >
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    v-on="on"
                    @click="showAdd('combustible')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.combustible.id"
                    v-on="on"
                    @click="showRemplace('combustible', model.combustible)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.combustible.id"
                    v-on="on"
                    @click="showAsociate('combustible', model.combustible)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('combustible', model.combustible.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CHANGE CARACTERISTICAS -->
    <v-dialog
      v-model="isChangeCaracteristicas"
      max-width="750px"
    >
      <v-card height="300px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.caracteristicas"
            class="mt-5"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="model.caracteristicas.name"
                :label="$t('menu.caracteristicas')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="model.caracteristicas.id"
                :items="itemsCaracteristicas"
                :search-input.sync="searchCaracteristicas"
                hide-details
                hide-selected
                :label="$t('menu.caracteristicas')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
                single-line
                @click:clear="clearCaracteristicas()"
                @change="setCaracteristicas()"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('menu.caracteristicas') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col
              v-if="iconSelect !== null && iconSelect !== ''"
              cols="12"
              md="1"
              class="text-center pt-4"
            >
              <v-icon
                v-if="model.caracteristicas.icon"
                size="30"
                color="primary"
                v-text="iconSelect"
              ></v-icon>
            </v-col>
            <v-col
              v-if="model.caracteristicas"
              cols="12"
              :md="iconSelect != null ? '5' : '6'"
            >
              <v-autocomplete
                v-model="model.caracteristicas.icon"
                :items="itemsIcons"
                :search-input.sync="searchIcons"
                hide-details
                hide-selected
                label="Icon"
                outlined
                dense
                item-text="name"
                item-value="name"
                clearable
                single-line
                @click:clear="iconSelect = null"
                @change="changeIcons"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Icon
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-group color="primary">
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon v-text="item.name"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="pl-5">
                          {{ item.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row
            v-if="model.caracteristicas"
            class="ml-2"
          >
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangeCaracteristicas = !isChangeCaracteristicas"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
              offset-md="2"
            >
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    v-on="on"
                    @click="showAdd('caracteristicas')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.caracteristicas.id"
                    v-on="on"
                    @click="showRemplace('caracteristicas', model.caracteristicas)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.caracteristicas.id"
                    v-on="on"
                    @click="showAsociate('caracteristicas', model.caracteristicas)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('caracteristicas', model.caracteristicas.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CHANGE TAGS -->
    <v-dialog
      v-model="isChangeTags"
      max-width="750px"
    >
      <v-card height="220px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.tags_img"
            class="mt-5"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="model.tags_img.name"
                label="Tag"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="model.tags_img.id"
                :items="itemsTags"
                :search-input.sync="searchTags"
                hide-details
                hide-selected
                label="Tag"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
                single-line
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Tag
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row class="ml-2">
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangeTags = !isChangeTags"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="model.tags_img"
              cols="12"
              md="6"
              offset-md="2"
            >
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    v-on="on"
                    @click="showAdd('tags_img')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.tags_img.id"
                    v-on="on"
                    @click="showRemplace('tags_img', model.tags_img)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.tags_img.id"
                    v-on="on"
                    @click="showAsociate('tags_img', model.tags_img)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('tags_img', model.tags_img.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import exportFromJSON from 'export-from-json'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiPlaylistEdit,
  mdiCancel,
  mdiInformationOutline,
  mdiListStatus,
  mdiFileReplaceOutline,
  mdiLinkVariantPlus,
  mdiMenuDown,
  mdiMenuRight,
  mdiFileDownload,
} from '@mdi/js'

export default {
  components: {
    AppCardCode,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_CAR,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiPlaylistEdit,
        mdiCancel,
        mdiInformationOutline,
        mdiListStatus,
        mdiFileReplaceOutline,
        mdiLinkVariantPlus,
        mdiMenuDown,
        mdiMenuRight,
        mdiFileDownload,
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      transmisiones: [],
      marcas: [],
      combustibles: [],
      caracteristicas: [],
      modelCaracteristicas: [],
      model: {},
      edad_min: {},
      loading: false,
      loadingDelete: false,

      items: [],

      isDialogVisible: false,
      isDialogVisibleDelete: false,
      isDialogVisibleDeleteItem: false,
      isDialogUpdate: false,
      nameOlds: [],
      newNameOld: {},
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      itemsModelos: [],
      allModelos: [],
      listModelos: [],
      searchModelos: null,

      itemsMarcas: [],
      allMarcas: [],
      listMarcas: [],
      searchMarcas: null,

      transmisionesChange: [],
      itemsTransmisiones: [],
      allTransmisiones: [],
      searchTransmisiones: null,

      tipoAutosChange: [],
      itemsTipoAuto: [],
      allTipoAuto: [],
      searchTipoAuto: null,

      combustiblesChange: [],
      itemsCombustibles: [],
      allCombustibles: [],
      searchCombustibles: null,

      caracteristicasChange: [],
      itemsCaracteristicas: [],
      allCaracteristicas: [],
      searchCaracteristicas: null,

      tagsChange: [],
      itemsTags: [],
      allTags: [],
      searchTags: null,

      intereses: [],
      searchIntereses: null,
      itemsIntereses: [],
      modelIntereses: [],
      servicios: [],
      itemsServicios: [],
      searchServicios: null,
      modelServicios: [],
      galery: [],
      editGeoTag: true,
      address: {},
      edades: [],
      numeros: [],
      file: null,
      uploadingFile: false,
      processUpload: false,
      cancelarUpload: false,
      isChangeMarca: false,
      isChangeModelo: false,
      isAddChange: false,
      isAddRemplace: false,
      isAddAsociate: false,
      isAddDelete: false,
      isChangeTransmicion: false,
      isChangeTipoAuto: false,
      isChangeCaracteristicas: false,
      isChangeCombustibles: false,
      isChangeTags: false,
      addChange: false,
      type: null,
      changing: false,
      lote: null,

      marcasChange: [],
      modelosChange: [],
      tiposDestinosChange: [],
      edadesChange: [],
      serviciosChange: [],
      interesesChange: [],
      logo: null,
      valueRemplace: null,
      valueCsv: null,
      idRemplace: null,

      iconito: null,
      iconSelect: null,
      iconos: [],
      itemsIcons: [],
      searchIcons: null,
      showRow: false,
      indexRow: -1,

      sampleData: [
        {
          Marca: 'GEELY',
          Modelo: 'CK 1,5 GS',
          Transmisión: 'manual',
          Carrocería: 'Sedan',
          Combustible: 'Gasolina',
          Puertas: 4,
          Pax: 5,
          'Maletas Grandes': 3,
          'Maletas Pequeñas': 2,
          'Tanque Combustible': 45,
          UM: 'L',
          Consumo: 13,
          Motor: 1.3,
          Características:
            'Aire acondicionado,Sistema de Audio CD,Sistema ABS,Dirección Asistida,Asientos moviles y seguros,Cinturones de seguridad delanteros y traseros,Cierre electrónico centralizado de puertas,Control electrónico de las ventanillas desde el asiento del conductor',
        },

        /* Imágenes: '10042/geely-CK-230.jpeg,10042/geely-CK-679.jpeg,10042/geely-CK-430.jpeg,10042/geely-CK-584.jpeg,10042/geely-CK-694.jpeg',
        Videos: '',
        'Id externo': 10, */
      ],
    }
  },
  computed: {
    ...mapState({
      addressHotel: state => state.app.addressHotel,
      onlyShow: state => state.app.onlyShow,
    }),
  },
  watch: {
    searchMarcas(val) {
      if (val !== null && val !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        this.filterMarcas(val.toLowerCase())
      } else {
        this.itemsMarcas = this.allMarcas
      }
    },
    searchModelos(val) {
      // eslint-disable-next-line no-unused-expressions
      this.filterModelos(val.toLowerCase())
    },
    searchTransmisiones(val) {
      // eslint-disable-next-line no-unused-expressions
      this.filterTransmisiones(val.toLowerCase())
    },
    searchTipoAuto(val) {
      // eslint-disable-next-line no-unused-expressions
      this.filterTipoAuto(val.toLowerCase())
    },
    searchCombustibles(val) {
      // eslint-disable-next-line no-unused-expressions
      this.filterCombustibles(val.toLowerCase())
    },
    searchIcons(val) {
      if (val !== null && val !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterIcons(val.toLowerCase())
      } else {
        this.itemsIcons = []
      }
    },
    searchCaracteristicas(val) {
      if (val !== null && val !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        this.filterCaracteristicas(val.toLowerCase())
      }
    },
    searchTags(val) {
      // eslint-disable-next-line no-unused-expressions
      this.filterTags(val.toLowerCase())
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    this.menuIsVerticalNavMini = true
  },
  created() {
    this.getNomAll()
    this.getLote()
  },
  methods: {
    ...mapMutations([
      'adicionarImgHotels',
      'updateImgHotels',
      'adicionarContactHotel',
      'updateContactHotel',
      'updateAddressHotel',
      'updateGeoTag',
      'setOnlyShow',
    ]),
    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },
    filterMarcas(v) {
      this.itemsMarcas = []
      if (v === '') {
        this.itemsMarcas = []
      } else {
        this.itemsMarcas = this.allMarcas.filter(e => e.name.toLowerCase())
      }
    },
    filterModelos() {
      this.itemsModelos = this.allModelos.filter(e => e.name.toLowerCase())
    },
    filterTransmisiones() {
      this.itemsTransmisiones = this.allTransmisiones.filter(e => e.name.toLowerCase())
    },
    filterTipoAuto() {
      this.itemsTipoAuto = this.allTipoAuto.filter(e => e.name.toLowerCase())
    },
    filterCombustibles() {
      this.itemsCombustibles = this.allCombustibles.filter(e => e.name.toLowerCase())
    },
    filterIcons(v) {
      this.itemsIcons = []
      if (v === '') {
        this.itemsIcons = []
      } else {
        this.itemsIcons = this.iconos.filter(e => e.name.toLowerCase())
      }
    },
    filterCaracteristicas(v) {
      this.itemsCaracteristicas = []
      if (v === '') {
        this.itemsCaracteristicas = []
      } else {
        this.itemsCaracteristicas = this.allCaracteristicas.filter(e => e.name.toLowerCase())
      }
    },
    filterTags() {
      this.itemsTags = this.allTags.filter(e => e.name.toLowerCase())
    },
    getNomAll() {
      this.getMarcas()
      this.getModelos()
      this.getTransmiciones()
      this.getTipoAuto()
      this.getCombustibles()
      this.getCaracteristicas()
      this.getIcons()
      this.getTags()
    },
    getMarcas() {
      this.axios
        .get(`nom_marca_cars?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allMarcas = res.data.data
        })
    },
    setMarca() {
      this.allModelos = []
      if (this.model.modelo.marca_id) {
        if (this.allMarcas.filter(e => e.id === this.model.modelo.marca_id)[0].modelos.length > 0) {
          this.allModelos = this.allMarcas.filter(e => e.id === this.model.modelo.marca_id)[0].modelos
          this.itemsModelos = this.allModelos
        }
      }
    },
    getModelos() {
      this.axios
        .get(`nom_model_cars?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allModelos = res.data.data
          this.listModelos = res.data.data
        })
    },
    getTransmiciones() {
      this.axios
        .get(`nom_transmision_cars?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.itemsTransmisiones = res.data.data
          this.allTransmisiones = res.data.data
        })
    },
    getTipoAuto() {
      this.axios
        .get(`nom_type_auto_cars?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allTipoAuto = res.data.data

          // this.itemsTipoAuto = res.data.data
        })
    },
    getCombustibles() {
      this.axios
        .get(`nom_combustible_cars?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allCombustibles = res.data.data
          this.itemsCombustibles = res.data.data
        })
    },
    getCaracteristicas() {
      this.axios
        .get(`nom_caracteristica_cars?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allCaracteristicas = res.data.data
        })
    },
    setCaracteristicas() {
      if (this.model.caracteristicas) {
        if (this.model.caracteristicas.id) {
          const fff = this.allCaracteristicas.filter(e => e.id === this.model.caracteristicas.id)
          if (fff.length > 0) {
            this.itemsIcons.push(this.iconos.filter(e => e.name === fff[0].icon)[0])
            this.model.caracteristicas.icon = fff[0].icon
            this.iconSelect = fff[0].icon
          }
        }
      }
    },
    clearCaracteristicas() {
      // this.iconSelect = null
      this.model.caracteristicas.icon = null
    },
    getTags() {
      this.axios
        .get('nom_tag_imagen_cars?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allTags = res.data.data
          this.itemsTags = res.data.data
        })
    },

    getLote() {
      this.axios
        .get('lotes-cars', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.lote = res.data.data.data
          if (this.lote) {
            this.marcasChange = res.data.data.data.marcas
            this.modelosChange = res.data.data.data.modelos
            this.transmisionesChange = res.data.data.data.transmisiones
            this.tipoAutosChange = res.data.data.data.tipo_autos
            this.combustiblesChange = res.data.data.data.combustibles
            this.caracteristicasChange = res.data.data.data.caracteristicas
            this.tagsChange = res.data.data.data.tags_img
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    getIcons() {
      this.axios
        .get('nom_icons/mdi', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.iconos = res.data.data
        })
    },
    changeIcons() {
      if (this.model.caracteristicas.icon) {
        this.iconSelect = this.model.caracteristicas.icon
      }
    },
    acceptUpload() {
      this.isDialogUpdate = true
    },
    canceleUpload() {
      this.isDialogVisibleDelete = true
    },
    confirmUpdateAll() {
      this.cancelarUpload = true
      this.axios
        .post(
          'lotes-cars/process-all',
          { id: this.lote.id },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 401) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            }
          } else {
            this.getLote()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.resetFile())
    },
    confirmCancele() {
      this.cancelarUpload = true
      this.axios
        .delete(`lotes-cars/${this.lote.id}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 401) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            }
          } else {
            this.getLote()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.resetFile())
    },
    modalMarca(item) {
      this.type = 'marca'
      this.model = {
        marca: {
          name: item,
          valueCsv: item,
        },
      }
      this.isChangeMarca = true
    },
    modalModelo(item) {
      this.model = {
        modelo: {
          name: item,
          marca_id: null,
          valueCsv: item,
        },
      }
      this.isChangeModelo = true
    },
    modalTransmision(item) {
      this.model = {
        transmision: {
          name: item,
          valueCsv: item,
        },
      }
      this.isChangeTransmicion = true
    },
    modalTipoAuto(item) {
      this.model = {
        tipo_auto: {
          name: item,
          description: null,
          valueCsv: item,
        },
      }
      this.isChangeTipoAuto = true
    },
    modalCombustibles(item) {
      this.model = {
        combustible: {
          name: item,
          valueCsv: item,
        },
      }
      this.isChangeCombustibles = true
    },
    modalCaracteristicas(item) {
      this.model = {
        caracteristicas: {
          name: item,
          valueCsv: item,
          icon: null,
        },
      }
      this.isChangeCaracteristicas = true
    },
    modalTags(item) {
      this.model = {
        tags_img: {
          name: item,
          valueCsv: item,
        },
      }
      this.isChangeTags = true
    },
    showAdd(type) {
      this.type = type
      this.isAddChange = true
    },
    showRemplace(type, newValue) {
      this.type = type
      this.valueRemplace = newValue.name
      this.valueCsv = newValue.valueCsv
      this.idRemplace = newValue.id
      this.isAddRemplace = true
    },
    showAsociate(type, newValue) {
      this.type = type
      this.valueRemplace = newValue.name
      this.valueCsv = newValue.valueCsv
      this.idRemplace = newValue.id
      this.isAddAsociate = true
    },
    showDelete(type, newValue) {
      this.type = type
      this.valueRemplace = newValue
      this.isAddDelete = true
    },

    confirmChange(type) {
      this.axios
        .put(
          `lotes/update/${this.lote.id}`,
          { type, changes: this.model },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },
    confirmAddChange() {
      this.addChange = true
      if (this.type === 'marca') {
        const formData = new FormData()
        if (this.logo) {
          formData.append('logo', this.logo)
        }

        formData.append('data', JSON.stringify({ type: this.type, changes: this.model }))
        this.axios
          .post(`lotes-cars/add-change-nom-logo/${this.lote.id}`, formData, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.$toast.success(this.$t('msg.infoSuccess'))
            this.getNomAll()
            this.getLote()
          })
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => this.resetFile())
      } else {
        this.axios
          .post(
            `lotes-cars/add-change/${this.lote.id}`,
            { type: this.type, changes: this.model },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.$toast.success(this.$t('msg.infoSuccess'))
            this.getNomAll()
            this.getLote()
          })
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => this.resetFile())
      }
    },
    confirmAddRemplace() {
      this.addChange = true
      const json = {
        newValue: this.valueRemplace,
        valueCsv: this.valueCsv,
        idValue: this.idRemplace,
        type: this.type,
      }

      if (this.model.servicios) {
        if (this.model.servicios.icon) {
          json.icon = this.model.servicios.icon
        }
      }

      this.axios
        .post(`lotes-cars/add-remplace/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },
    confirmAddAsociate() {
      this.addChange = true
      const json = {
        newValue: this.valueRemplace,
        valueCsv: this.valueCsv,
        idValue: this.idRemplace,
        type: this.type,
      }

      if (this.model.servicios) {
        if (this.model.servicios.icon) {
          json.icon = this.model.servicios.icon
        }
      }

      this.axios
        .post(`lotes-cars/add-asociate/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },
    confirmAddDelete() {
      this.addChange = true
      const json = {
        newValue: this.valueRemplace,
        type: this.type,
      }

      this.axios
        .post(`lotes-cars/add-delete/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },
    deleteItem(item) {
      this.isDialogVisibleDeleteItem = true
      this.model = {
        marca: {
          id: item.id,
          name: item.marca.name,
        },
      }
    },
    confirmDelete() {
      this.loadingDelete = true
      const json = {
        id: this.model.marca.id,
      }

      this.axios
        .post(`lotes-cars/delete-item/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .finally(() => this.resetFile())
    },

    setFile() {
      if (this.file) {
        this.uploadingFile = true
        const formData = new FormData()
        formData.append('file', this.file)
        this.axios
          .post('cars/upload-lote', formData, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          // eslint-disable-next-line no-unused-vars
          .then(response => {
            if (!response.data.success) {
              this.$toast.error(response.data.message)
            } else {
              this.$toast.success(this.$t('msg.verifiqueInfo'))

              this.getLote()
            }
          })
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => this.resetFile())
      }
    },
    resetFile() {
      this.file = null
      this.model = {}
      this.logo = null
      this.type = null
      this.iconito = null
      this.iconSelect = null
      this.uploadingFile = false
      this.isDialogVisibleDeleteItem = false
      this.isDialogUpdate = false
      this.loadingDelete = false
      this.cancelarUpload = false
      this.isDialogVisibleDelete = false
      this.isChangeMarca = false
      this.isChangeModelo = false
      this.isChangeTransmicion = false
      this.isChangeTipoAuto = false
      this.isChangeCombustibles = false
      this.isChangeCaracteristicas = false
      this.isChangeTags = false
      this.isAddChange = false
      this.isAddRemplace = false
      this.addChange = false
      this.valueRemplace = null
      this.valueCsv = null
      this.idRemplace = null
      this.isAddAsociate = false
      this.isAddDelete = false

      this.searchMarcas = null
      this.searchTransmisiones = null
      this.searchModelos = null
      this.searchTipoAuto = null
      this.searchCombustibles = null
      this.searchCaracteristicas = null
      this.searchIcons = null
      this.searchTags = null
    },

    dowloadExportCsv() {
      this.exportCsv('Lote', this.sampleData, 'csv', this.fields)
    },

    exportCsv(newFileName, data, fileExportType) {
      const fileName = newFileName || 'exported-data'
      const exportType = exportFromJSON.types[fileExportType || 'csv']
      exportFromJSON({
        data,
        fileName,
        exportType,
      })
    },
  },
}
</script>
<style>
/**PARA OCULTAR LA FECHA DE HOY EN EL COMPONENTE DE HORA */
.vc-date-time .vc-date .vc-weekday,
.vc-date-time .vc-date .vc-month,
.vc-date-time .vc-date .vc-day,
.vc-date-time .vc-date .vc-year {
  display: none;
}
</style>
